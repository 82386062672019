.swiper-container {
  width: 100%;
  height: 170px;
  margin: 20px auto;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mySwiper {
  color: #46818b;
}

.swiper-button-prev,
.swiper-button-next {
  color: #5196a2 !important;
}
